<template>
  <div id="patient-data-content">
    <div class="patient-data">
      <div class="d-flex justify-content-between align-items-center">
        <p class="patient-title m-0"> Paciente
          <span v-if="patient" @click="goPatientProfile" class="patient-profile ml-2"> -
            <span> ver perfil </span>
          </span>
        </p>

        <b-badge
          v-if="patient && patient.cellphone && appointment?.id && appointment?.status === 'SCHEDULED'"
          class="whats-app-badge"
          pill
          @click="sendWhatsAppToPatient"
        >
          <WhatsAppIcon2 />
          <span v-if="alreadySendMessage">
            Reenviar mensagem
          </span>
          <span v-else>
            Enviar mensagem de confirmação
          </span>
        </b-badge>
      </div>
      <multiselect
        ref="patient"
        v-model="patient"
        class="patient-name"
        :options="patients"
        track-by="id"
        label="name"
        :showLabels="false"
        :allowEmpty="false"
        :loading="loading"
        :internal-search="false"
        @search-change="debounceGetPatients"
        @select="selectPatient"
        placeholder="Selecionar paciente"
        :disabled="disabled"
      >
        <template slot="singleLabel" slot-scope="props">
          <div class="patient-name-doctor">
            {{ props.option.name }}
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div>
            <div class="patient-name">{{ props.option.name }}</div>
            <div class="patient-props">
              <div>{{ parseBirthDay(props.option.birthday) }}</div>
              <div v-if="props.option.birthday && props.option.cpf">|</div>
              <div v-if="props.option.cpf" class="patient-cpf">CPF {{ props.option.cpf }}</div>
            </div>
          </div>
        </template>

        <template slot="placeholder">
          <span class="multiselect__placeholder">Nome do paciente</span>
        </template>

        <template slot="noOptions">
          <div>Digite para pesquisar seus pacientes...</div>
        </template>
        <template slot="noResult">
          <div>Não encontrado paciente cadastrado com este nome...</div>
        </template>

        <template slot="afterList">
          <li @click="addNewPatient(patientSearchQuery)">
            <div class="multiselect__option new-patient">
              <Plus class="icon" />
              Cadastrar novo paciente "{{ patientSearchQuery }}"
            </div>
          </li>
        </template>

        <template slot="singleLabel" slot-scope="{ option }">
          {{ option.name }}
        </template>
      </multiselect>

      <div v-if="patient?.id" class="divider" />

      <div v-if="patient" class="inline-flex">
        <div v-if="patient.birthday" class="flex">
          <div class="birthday-wrapper" :class="{ active: isBirthday }">
            <div class="age">Nascimento:</div>
            <div class="birthday" v-if="patient !== null && patient.birthday &&moment(patient.birthday).isValid()">
              {{ moment(patient.birthday).format('DD/MM/YYYY') }}
            </div>
            <div class="years" v-if="patient.birthday">
              <p class="value" v-if="moment().diff(moment(patient.birthday),'days') <= 30 ">
                {{ moment().diff(moment(patient.birthday), 'days') }} dias
              </p>
              <p class="value"
                 v-else-if="moment().diff(moment(patient.birthday),'month') > 1 && moment().diff(moment(patient.birthday),'month') < 12">
                {{ moment().diff(moment(patient.birthday), 'month') }} meses
              </p>
              <p class="value" v-else-if="!patient.birthday">
                -
              </p>
              <p class="value" v-else>
                ({{ moment().diff(moment(patient.birthday), 'years') }} anos)
              </p>
            </div>
            <img v-if="isBirthday" class="confetti" src="@/assets/gifs/confetti.gif" v-b-tooltip.hover
                 title="Agendamento no dia do aniversário" />
          </div>
        </div>

        <div v-if="patient.cellphone" class="flex">
          <div>Celular:</div>
          <a class="whatsapp-link" @click="openWhatsApp(patient.cellphone)"
             v-if="patient !== null && patient.cellphone">
            <span>
              <p>{{ patient.cellphone }}</p>
            </span>
            <WhatsAppIcon />
          </a>
        </div>

        <div v-if="patient?.medical_record_number" class="flex">
          <div>Prontuário:</div>
          <a class="medical-record-container" @click="goPatient" v-if="patient.medical_record_number != null">
            <span>
              {{ patient?.medical_record_number }}
            </span>
            <Export />
          </a>
        </div>
      </div>

      <div v-if="patient && (!patient.address_id || !patient.name)">
        <span class="patient-alert">
          Paciente com cadastro incompleto, finalize o cadastro deste paciente
          <router-link :to="`/pacientes/${patient.id}/edit`">
            Clicando aqui.
          </router-link>
        </span>
      </div>

    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { getCurrentClinic } from '@/utils/localStorageManager'
import WhatsAppIcon from '@/assets/icons/whatsapp-icon.svg'
import WhatsAppIcon2 from '@/assets/icons/whatsapp-icon2.svg'
import Export from '@/assets/icons/export.svg'
import Plus from '@/assets/icons/plus.svg'

export default {
  props: {
    value: Object,
    appointment: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WhatsAppIcon2,
    WhatsAppIcon,
    Export,
    Plus
  },
  computed: {
    isBirthday() {
      if (!this.appointment?.start_datetime || !this.appointment?.patient?.birthday) return false
      return this.moment(this.appointment?.start_datetime).format('MM-DD') === this.moment(this.appointment?.patient?.birthday).format('MM-DD')
    }
  },
  async mounted() {
    await this.getClinicAddress()
  },
  data() {
    return {
      debounceGetPatients: debounce(this.searchPatient, 300),
      clinic: getCurrentClinic(),
      clinic_address: null,
      loading: false,
      patient: null,
      patientSearchQuery: null,
      patients: [],
      alreadySendMessage: false
    }
  },
  methods: {
    parseBirthDay(birthday) {
      if (!birthday) return ''
      return this.moment(birthday).format('DD/MM/YYYY')
    },
    searchPatient(query) {
      this.loading = true
      this.patientSearchQuery = query
      this.api.searchPatients(this.clinic.id, query)
        .then(res => {
          this.patients = res.data
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          this.loading = false
        })
    },
    selectPatient(patient) {
      this.$emit('input', patient)
      this.$emit('select', patient)
      this.$emit('selectDefaultHealthPlan')
    },
    addNewPatient(searchTerm) {
      this.eventHub.$emit('new-patient-name', searchTerm)
      this.$bvModal.show('new-patient-modal')
    },
    goPatientProfile() {
      const route = this.$router.resolve(`/pacientes/${this.patient.id}/edit`)
      window.open(route.href, '_blank')
    },
    goPatient() {
      const route = this.$router.resolve(`/pacientes/${this.patient.id}`)
      window.open(route.href, '_blank')
    },
    async getClinicAddress() {
      try {
        const response = await this.api.getClinicAddress(this.clinic.id)
        this.clinic_address = response.data
      } catch (err) {
        console.error(err)
      }
    },
    openWhatsApp(patient) {
      const whatsappNumber = patient.replace(/\D/g, '')
      if (whatsappNumber.length < 10) {
        this.$toast.warning('Número de telefone sem DDD')
        return
      }
      window.open(`https://api.whatsapp.com/send?phone=55${whatsappNumber}`, '_blank')
    },
    sendWhatsAppToPatient() {
      const whatsappNumber = this.patient.cellphone.replace(/\D/g, '')
      if (whatsappNumber.length < 10) {
        this.$toast.warning('Número de telefone sem DDD')
        return
      }

      const place = this.appointment.professional ? `, com Dr.(a) ${this.appointment.professional.name}` : ``
      let message = `https://api.whatsapp.com/send?phone=55${whatsappNumber}&text=Olá ${this.patient.name}, este é um lembrete para sua consulta em ${this.clinic.name} na ${this.moment(this.appointment.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('LLLL')}${place}. Clique neste link para confirmar ou cancelar seu agendamento:%0a%0a`
      message += `${process.env.VUE_APP_PUBLIC_SCHEDULE_URL}appointment/${this.appointment.id}`
      if (!this.appointment.telemedicine) {
        message += `. No seguinte endereço: ${this.clinic_address.address} - ${this.clinic_address.number}, ${this.clinic_address.complement} - CEP: ${this.clinic_address.zipCode}, ${this.clinic_address.neighborhood}, ${this.clinic_address.city}.`
      }

      window.open(message, '_blank')
      this.api.createLog({
        clinic_id: this.clinic.id,
        log_type: 'App\\Models\\Appointment',
        log_id: this.appointment.id,
        category: 'update',
        description: 'Mensagem de confirmação do agendamento enviado para WhatsApp do paciente',
        date_time: this.moment().format('YYYY-MM-DD HH:mm:ss')
      })
        .then(() => {
          this.alreadySendMessage = true
        })
    },
    alreadySendMessageFunc() {
      this.api.checkAppointmentPatientMessage({
        appointment_id: this.appointment.id
      })
        .then(res => {
          if (res.data?.id) this.alreadySendMessage = true
        })
        .catch(err => this.$toast.error(err.message))
    }
  },
  watch: {
    value(patient) {
      this.patient = patient
    },
    patient(patient) {
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        this.$refs?.patient?.$refs?.search?.blur()
      }, 500)
    },
    appointment(appointment) {
      if (appointment?.id) this.alreadySendMessageFunc()
    }
  }
}
</script>

<style lang="scss">
#patient-data-content {
  width: 100%;

  .patient-data {
    p {
      margin-bottom: 0;
    }

    .patient-title {
      display: flex;
      color: #304388;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .multiselect--disabled {
      opacity: 1;

      .multiselect__select {
        background: #ffffff;
        color: #ffffff;
      }

      .multiselect__tags {
        background: #ffffff;
        color: #ffffff;
      }
    }

    .patient-name-doctor {
      font-family: Nunito Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0em;
      color: #304388;
      padding-left: 0px;

      .export {
        color: #305BF2;
        font-weight: 700;
        margin-right: 2px;
        cursor: pointer;
      }
    }

    .divider {
      width: 100%;
      height: .5px;
      background-color: var(--neutral-200);
      margin: 10px 0;
    }

    .patient-name {
      margin-bottom: 8px;

      .multiselect__placeholder {
        color: #8696AC;
        font-size: 24px;
        padding: 0px;
      }

      .multiselect__single {
        font-family: Nunito Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        color: #304388;
        padding-left: 0px;

        .export {
          color: #305BF2;
          font-weight: 700;
          margin-right: 2px;
          cursor: pointer;
        }
      }

      &.multiselect--disabled {
        opacity: 1;

        .multiselect__select {
          background: #ffffff;
          color: #ffffff;
        }

        .multiselect__tags {
          background: #ffffff;
          color: #ffffff;
        }
      }
    }

    .multiselect {
      margin-bottom: 8px;

      .multiselect__placeholder {
        color: #8696AC;
        font-size: 24px;
        padding: 0px;
      }

      .multiselect__single {
        font-family: Nunito Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        color: #304388;
        padding-left: 0px;
      }
    }

    input:focus {
      outline: none !important;
      border-color: #305BF2;
    }
  }

  .birthday-wrapper {
    display: flex;
    border-radius: 5px;
    align-items: center;
    flex-direction: row;
    padding: 0px 4px;

    &.active {
      border: 1px solid var(--orange);
      margin-left: 5px;
    }

  }

  .confetti {
    width: 24px;
  }

  .patient-name {
    color: var(--type-active);
    font-size: 16px;
  }

  .patient-profile > span {
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    color: var(--blue-500);

    &:hover {
      text-decoration: underline;
    }
  }

  .patient-props {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--type-placeholder);
    font-size: 14px;

    .patient-cpf {
      color: var(--blue-500);
      font-weight: 400;
    }
  }

  .inline-flex {
    display: inline-flex;
    align-items: center;
    gap: 20px;
    font-size: 13px;
    margin-bottom: 1rem;
  }

  .flex {
    display: flex;
    align-items: center !important;
    gap: 5px;
  }

  .birthday {
    color: var(--type-active);
    font-weight: 700;
  }

  .years {
    color: var(--type-placeholder);
  }

  .whatsapp-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    text-decoration: underline;
    cursor: pointer;

    span {
      font-weight: 700;

      p {
        color: var(--blue-500);
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        text-decoration: none;
      }
    }

    svg {
      height: 28px;
      width: 28px;
    }
  }

  .medical-record-container, .patient-profile {
    display: flex;
    flex-direction: row;
    gap: 8px;
    cursor: pointer;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--blue-500);
      font-weight: 700;
      cursor: pointer;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .whats-app-badge {
    padding: 15px;
    background: var(--blue-100);
    color: var(--blue-500);
    cursor: pointer;
  }

  .whats-app-badge:hover {
    background: var(--blue-300);
    color: var(--blue-100);
  }
}
</style>
